@font-face {
  font-family: "LaFabR";
  src: url("../assets/fonts/LaFabrique-Regular.woff") format("woff"); /* Pretty Modern Browsers */
}

@font-face {
  font-family: "LaFabLt";
  src: url("../assets/fonts/LaFabrique-Light.woff") format("woff"); /* Pretty Modern Browsers */
}

// @font-face {
//   font-family: $font-header;
//   src: url('../fonts/G-Regular.woff2') format('woff2'),
//     url('../fonts/G-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: $font-header;
//   src: url('../fonts/G-Bold.woff2') format('woff2'),
//     url('../fonts/G-Bold.woff') format('woff');
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }