html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: LaFabR;
  src: url("LaFabrique-Regular.119851f5.woff") format("woff");
}

@font-face {
  font-family: LaFabLt;
  src: url("LaFabrique-Light.dcf12c2e.woff") format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  font-size: 15px;
}

body {
  font-family: LaFabR;
  line-height: 2.94118ex;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  font-family: LaFabR;
}

h1 {
  margin-bottom: 3rem;
  font-size: 6rem;
}

h2 {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}

h3 {
  margin-bottom: .5rem;
  font-size: 3rem;
}

p {
  margin-bottom: 1rem;
  margin-block-end: 2rem;
  font-family: LaFabR;
  font-size: 1rem;
}

ol {
  margin-block: 1em;
  margin-inline: 0;
  padding-inline-start: 20px;
  list-style: decimal;
}

* {
  box-sizing: border-box;
  visibility: inherit;
  opacity: inherit;
}

html {
  touch-action: none;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

body {
  -webkit-overflow-scrolling: touch;
  color: #e6e6e6;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden auto;
}

.button {
  text-transform: uppercase;
  background: none;
  border: none;
  padding: .5rem 2rem;
  transition: all .2s;
}

.button:hover {
  cursor: pointer;
}

.button--toggle, .button--close {
  color: #e6e6e6;
  border: 2px solid #0000;
  border-radius: 50%;
  padding: .25rem;
  transition: transform .2s, border-color .4s;
  transform: scale(.7);
}

.button--toggle svg, .button--close svg {
  display: block;
}

.button--toggle:hover, .button--close:hover {
  cursor: pointer;
  border: 2px solid #fff9;
  transform: scale(.7);
}

.button--toggle.active, .button--close.active {
  transform: rotate(135deg)scale(.7);
}

.button--toggle.active:hover, .button--close.active:hover {
  cursor: pointer;
  transform: rotate(135deg)scale(.7);
}

.button--close {
  transform: rotate(45deg)scale(.7);
}

.button--close:hover {
  cursor: pointer;
  border: 2px solid #fff9;
  transform: rotate(45deg)scale(.7);
}

.button--primary {
  color: #fff;
  background: #000;
  border: 1px solid #fff9;
}

.button--primary:hover {
  color: #000;
  background: #fff;
}

.button--primary:disabled {
  color: #ffffff4d;
  border: 1px solid #ffffff4d;
}

.button--primary:disabled:hover {
  background: #141414;
}

.button--text {
  color: #e6e6e6;
  background: none;
  border: 0;
  text-decoration: underline;
}

.button--text:hover {
  cursor: pointer;
}

input {
  color: #fff;
  background: #000;
  border: 1px solid #fff9;
  min-width: 0;
  padding: .5rem;
}

input[type="checkbox"] {
  cursor: pointer;
  background: #000;
  width: 1.2rem;
  height: 1.2rem;
  margin: 0;
}

a {
  color: #e6e6e6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.modal__wrapper {
  visibility: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal {
  z-index: 2;
  background: #000000f0;
  border: 1px solid #fff3;
  flex-direction: column;
  width: 80%;
  max-width: 400px;
  max-height: 600px;
  margin: 2rem;
  display: flex;
  position: relative;
  box-shadow: 0 3px 6px 2px #000c;
}

@media (width <= 569px) {
  .modal {
    width: 100%;
    margin: 80px 1rem 2rem;
  }
}

.modal__header {
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 1px solid #fff3;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 1rem;
  font-size: .8rem;
  line-height: normal;
  display: flex;
  position: relative;
}

.modal--large {
  max-width: 800px;
}

.modal__content {
  text-align: center;
  overflow: auto;
}

.modal__footer {
  text-align: center;
  width: 100%;
  bottom: 0;
}

#zine_form {
  flex-direction: column;
  gap: 30px;
  max-width: 22rem;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
}

#zine_form .zine-input {
  flex: 1;
}

#zine_form .zine__check_wrapper label {
  position: relative;
  top: -2px;
}

#zine_form .form__wrapper {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  display: grid;
}

#zine_form .field__full {
  grid-column: span 2;
}

#zine_form input[type="checkbox"] {
  width: 20px;
  margin: 0 .3rem;
}

#zine_form p {
  margin-bottom: 0;
  margin-block-end: 0;
}

#interactive {
  position: fixed;
  inset: 0;
}

.header {
  z-index: 2;
  padding: 1rem;
  position: relative;
}

.content {
  z-index: 1;
  visibility: hidden;
  background: #000000e6;
  grid-template-columns: 2fr 1fr 1.5fr;
  gap: 4rem;
  padding: 6rem;
  display: grid;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
}

.content:after {
  content: "";
  background: linear-gradient(#0000, #000);
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
}

.content section {
  visibility: hidden;
}

.content .clients {
  text-transform: uppercase;
}

.content .clients ul li {
  white-space: nowrap;
  visibility: hidden;
}

@media (width <= 1023px) {
  .content {
    grid-template-columns: 2fr 1fr;
  }
}

@media (width <= 767px) {
  .content {
    grid-template-columns: 1fr;
    padding: 5rem 1.8rem;
  }

  .content .clients {
    columns: 2;
  }
}

@media (width <= 569px) {
  .content .clients {
    text-transform: none;
    column-gap: 3rem;
  }
}

.footer {
  z-index: 2;
  text-transform: uppercase;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  font-family: LaFabLt;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width <= 767px) {
  .footer {
    justify-content: center;
  }
}

.footer.fill {
  background: #000;
}

.footer__nav {
  gap: .5rem;
  padding: 0 2rem;
  font-size: 1.2rem;
  display: flex;
}

@media (width <= 767px) {
  .footer__nav {
    justify-content: center;
    align-items: center;
    gap: .2rem;
    font-size: .9rem;
  }
}

.announcement {
  visibility: hidden;
  opacity: 0;
  text-align: center;
  background: #000c;
  border: 1px solid #fff6;
  width: 300px;
  padding: 10px;
  position: absolute;
  bottom: 10vh;
  right: 10vw;
}

@media (width <= 767px) {
  .announcement {
    width: 140px;
  }
}

.announcement__image_wrapper {
  transition: all .1s ease-in-out;
  position: relative;
}

.announcement__image_wrapper:hover {
  cursor: pointer;
}

.announcement .announcement__header {
  text-align: left;
  font-size: large;
  position: absolute;
}

.announcement__image {
  aspect-ratio: 16 / 9;
  max-width: 100%;
  display: block;
}

.privacy__policy {
  text-align: left;
  margin: 2rem .5rem;
}

.background__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.flame__video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.video__copy {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 767px) {
  .video__copy {
    align-items: flex-start;
    padding-top: 20%;
  }
}

.video__copy p {
  color: #000;
  max-width: 50%;
  font-size: 8rem;
  font-weight: bold;
  line-height: 1;
}

@media (width <= 767px) {
  .video__copy p {
    max-width: 100%;
    font-size: 3rem;
  }
}
/*# sourceMappingURL=index.e67b549a.css.map */
