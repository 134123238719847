.modal__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.modal {
  background: rgba(0, 0, 0, 0.94);
  border: 1px solid $color-border-lighter;
  z-index: 2;
  margin: 2rem;
  width: 80%;
  max-width: 400px;
  max-height: 600px;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.8);
  position: relative;
  display: flex;
  flex-direction: column;
  @include media("<xs") {
    margin: 80px 1rem 2rem;
    width: 100%;
  }
}

.modal__header {
  width: 100%;
  border-bottom: 1px solid $color-border-lighter;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 2px;
  font-size: 0.8rem;
  position: relative;
}

.modal--large {
  max-width: 800px;
}

.modal__content {
  text-align: center;
  overflow: auto;
}

.modal__footer {
  text-align: center;

  bottom: 0;
  width: 100%;
}
