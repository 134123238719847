#zine_form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;
  max-width: 22rem;
  margin: 0 auto;

  .zine-input {
    flex: 1;
  }

  .zine__check_wrapper {
    label {
      position: relative;
      top: -2px;
    }
  }

  .form__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .field__full {
    grid-column: span 2;
  }

  input[type="checkbox"] {
    margin: 0 0.3rem;
    width: 20px;
  }

  p {
    margin-bottom: 0;
    margin-block-end: 0;
  }
}
