html {
  font-size: 15px;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $font-body;
  line-height: calc(1ex / 0.34);
}

h1,h2,h3,h4,h5 {
  font-family: $font-header;
  text-transform: uppercase;
}

h1 {
  font-size: 6rem;
  margin-bottom: 3rem;
}

h2 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

p {
  font-family: $font-body;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-block-end: 2rem;
}

ol {
  list-style: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

li {

}