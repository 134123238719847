* {
  box-sizing: border-box;
  visibility: inherit;
  opacity: inherit;
}

html {
  position: fixed;
  height: 100%;
  overflow: hidden;
  touch-action: none;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: black;
  color: $color-text;
}

.button {
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  border: none;
  background: none;
  transition: all 0.2s;
  &:hover {
    cursor: pointer;
  }
}

.button--toggle,
.button--close {
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  color: $color-text;
  transition: transform 0.2s, border-color 0.4s;
  transform: scale(0.7);
  padding: 0.25rem;
  svg {
    display: block;
  }

  &:hover {
    transform: scale(0.7);
    cursor: pointer;
    border: 2px solid $color-border;
  }

  &.active {
    transform: rotate(135deg) scale(0.7);
    &:hover {
      transform: rotate(135deg) scale(0.7);
      cursor: pointer;
    }
  }
}

.button--close {
  transform: rotate(45deg) scale(0.7);

  &:hover {
    transform: rotate(45deg) scale(0.7);
    cursor: pointer;
    border: 2px solid $color-border;
  }
}

.button--primary {
  color: $color-button-text;
  border: 1px solid $color-border;
  background: $color-button-bg;
  &:hover {
    background: $color-button-bg-hover;
    color: $color-button-text-hover;
  }
  &:disabled {
    color: $color-button-disabled;
    border: 1px solid $color-button-disabled;
    &:hover {
      background: $color-button-bg-hover-disabled;
    }
  }
}

.button--text {
  color: $color-text;
  background: none;
  border: 0;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}


input {
  padding: 0.5rem;
  background: $color-button-bg;
  color: $color-button-text;
  border: 1px solid $color-border;
  min-width: 0;
}

input[type="checkbox"] {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 1rem;
  margin: 0;
  background: $color-button-bg;
  cursor: pointer;
}


a {
  color: $color-text;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}