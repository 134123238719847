#interactive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header {
  padding: 1rem;
  position: relative;
  z-index: 2;
}

.content {
  background: $color-overlay;
  z-index: 1;
  visibility: hidden;
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr;
  gap: 4rem;
  padding: 6rem 6rem;

  &::after {
    content: "";
    width: 100%;
    height: 60px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: $footer-height;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }

  section {
    visibility: hidden;
  }

  .clients {
    text-transform: uppercase;
    ul li {
      white-space: nowrap;
      visibility: hidden;
    }
  }

  @include media("<tablet") {
    grid-template-columns: 2fr 1fr;
  }

  @include media("<phone") {
    grid-template-columns: 1fr;
    padding: 5rem 1.8rem;
    .clients {
      columns: 2;
    }
  }
  @include media("<xs") {
    .clients {
      column-gap: 3rem;
      text-transform: none;
    }
  }
}

.footer {
  z-index: 2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  height: $footer-height;
  font-family: "LaFabLt";
  text-transform: uppercase;

  @include media("<phone") {
    justify-content: center;
  }

  &.fill {
    background: black;
  }

  &__nav {
    font-size: 1.2rem;
    display: flex;
    gap: 0.5rem;
    padding: 0 2rem;

    @include media("<phone") {
      gap: 0.2rem;
      font-size: 0.9rem;
      align-items: center;
      justify-content: center;
    }
  }
}

.announcement {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  bottom: 10vh;
  right: 10vw;
  width: 300px;
  text-align: center;

  @include media("<phone") {
    width: 140px;
  }

  &__image_wrapper {
    position: relative;
    transition: all 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  .announcement__header {
    position: absolute;
    text-align: left;
    font-size: large;
  }

  &__image {
    max-width: 100%;
    aspect-ratio: 16 / 9;
    display: block;
  }
}

.privacy__policy {
  margin-top: 1rem;
  text-align: left;
  margin: 2rem 0.5rem;
}

.background__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: purple;
}

.flame__video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video__copy {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<phone") {
    align-items: flex-start;
    padding-top: 20%;
  }
  p {
    color: black;
    font-size: 8rem;
    font-weight: bold;
    max-width: 50%;
    line-height: 1;
    @include media("<phone") {
      max-width: 100%;
      font-size: 3rem;
    }
  }
}
